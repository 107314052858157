import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Font } from 'three/examples/jsm/loaders/FontLoader';
import { PencilLinesPass } from "./PencilLinesPass";

import calendar from "./calendar.json";
type CalendarEntry = {
  date: string;
  title: string;
  author: string;
  year: number;
}

const scene = new THREE.Scene();
const frustumSize = 11;
const aspect = window.innerWidth / window.innerHeight;
const camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, 1, 1000 );
function formatCalendar(calendar: CalendarEntry[]) {
  const groupedByMonth: { [key: string]: CalendarEntry[] } = {};

  calendar.forEach(entry => {
    const date = new Date(entry.date);
    const month = date.toLocaleString('default', { month: 'long' }).toLowerCase();
    if (!groupedByMonth[month]) {
      groupedByMonth[month] = [];
    }
    groupedByMonth[month].push(entry);
  });

  let result = '';
  let first = true;
  for (const month in groupedByMonth) {
    if (!first) {
      result += '\n';
    }
    result += `${month}\n`;
    groupedByMonth[month].forEach(entry => {
      const date = new Date(entry.date).getDate().toString().padStart(2, ' ');
      result += ` ${date} | ${entry.title} [${entry.author}, ${entry.year}]\n`;
    });
    first = false;
  }

  return result.trim();
}


function getRandomCoordinatesInShell(outerRadius: number, innerRadius: number): { x: number, y: number, z: number } {
  let x, y, z, r;
  do {
    const u = Math.random();
    const v = Math.random();
    const theta = 2 * Math.PI * u;
    const phi = Math.acos(2 * v - 1);
    r = outerRadius * Math.cbrt(Math.random());

    x = r * Math.sin(phi) * Math.cos(theta);
    y = r * Math.sin(phi) * Math.sin(theta);
    z = r * Math.cos(phi);
  } while (r < innerRadius);

  return { x, y, z };
}

const box = new THREE.BoxGeometry();
for (let i = 0; i < 3500; i++) {
  const object = new THREE.Mesh(
    box,
    new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff })
  );

  const { x, y, z } = getRandomCoordinatesInShell(45 , 12);
  object.position.x = x;
  object.position.y = y;
  object.position.z = z;

  object.rotation.x = Math.random() * 2 * Math.PI;
  object.rotation.y = Math.random() * 2 * Math.PI;
  object.rotation.z = Math.random() * 2 * Math.PI;

  object.scale.x = Math.random() + 0.8;
  object.scale.y = Math.random() + 0.8;
  object.scale.z = Math.random() + 0.8;

  scene.add(object);
}

import fontJson from "./font.json";
const font = new Font(fontJson);
const text = new TextGeometry(
  formatCalendar(calendar),
  {
    font: font,
    size: 0.2,
    curveSegments: 50,
    depth: 0,
  }
);
text.computeBoundingBox();

const mesh = new THREE.Mesh(text, new THREE.MeshBasicMaterial());
mesh.position.x -= text.boundingBox.max.x / 2 + -1;

scene.add(mesh);


const title = new TextGeometry(
  "mosaic club",
  {
    font: font,
    size: 0.7,
    height: 0,
    depth: 0.1,
    curveSegments: 50,
  }
);
title.center();

const titleMesh = new THREE.Mesh(title, new THREE.MeshBasicMaterial());
titleMesh.position.x = 0;
titleMesh.position.y = 2;
titleMesh.position.z = -1;
scene.add(titleMesh);


camera.position.z = 12;
camera.position.y = 0;
camera.position.x = 0;

const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor("#eee");
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.CineonToneMapping;
renderer.toneMappingExposure = 1;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
document.body.appendChild(renderer.domElement);

const composer = new EffectComposer(renderer);
const renderPass = new RenderPass(scene, camera);
const pencilLinePass = new PencilLinesPass({
  width: renderer.domElement.clientWidth,
  height: renderer.domElement.clientHeight,
  scene,
  camera,
});

composer.addPass(renderPass);
composer.addPass(pencilLinePass);

const controls = new OrbitControls(camera, renderer.domElement);

function animate() {
  requestAnimationFrame(animate);
  controls.update();
  composer.render();
}

window.addEventListener("resize", onWindowResize);

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}

animate();
